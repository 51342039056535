import { template as template_289e493b8b634941ab1f468dd55f42c9 } from "@ember/template-compiler";
const FKLabel = template_289e493b8b634941ab1f468dd55f42c9(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
