import { template as template_b46e5b7135454ed58b962c54fd83e3dd } from "@ember/template-compiler";
const WelcomeHeader = template_b46e5b7135454ed58b962c54fd83e3dd(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
