import { template as template_2110f3bcc84644c3b535dab4efd02410 } from "@ember/template-compiler";
const SidebarSectionMessage = template_2110f3bcc84644c3b535dab4efd02410(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
