import { template as template_00cf1582048d47a699e4609fc96d099f } from "@ember/template-compiler";
const FKText = template_00cf1582048d47a699e4609fc96d099f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
