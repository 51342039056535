import { template as template_ffd3fbfbbe1145fdbee82af49283055c } from "@ember/template-compiler";
const FKControlMenuContainer = template_ffd3fbfbbe1145fdbee82af49283055c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
