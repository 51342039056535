import { template as template_b841f76250e74e9b9fb05e0ed2ab25e3 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_b841f76250e74e9b9fb05e0ed2ab25e3(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
